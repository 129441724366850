$breakpoints: (xs: 480px, sm: 640px, md: 960px, lg: 1280px)

$color-grey: #2a2a2a
$color-sushi: #72be44
$color-japaneseLaurel: #008506
$color-crimson: #e9132b
$color-freshEggplant: #850080
$color-chambray: #394f82
$color-ming: #396f82

$base-font-size: 16
$color-bg: #444fb6
$color-footer-bg: #2f3995