@use '../../common/styles/vars' as *
@use 'sass-mq' as * with ($breakpoints: $breakpoints)

.Footer
    background: $color-footer-bg
    font-weight: 100
    font-size: 13px
    color: #fff
    padding: 24px 0
    border-radius: 24px
    margin: 24px
    position: relative
    +mq(sm)
      font-size: 14px

    &-Controls
      width: 100%
      display: flex
      align-items: center
      padding: 0 24px 20px
      justify-content: space-between
      +mq(md)
        position: absolute
        height: 100%
        padding-bottom: 0
        margin-top: -24px

    &-Conditions
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      padding: 0 24px
      width: 80%
      margin: 0 auto
      z-index: 2

    &-Links
        text-align: center
        margin-bottom: 10px

    &-Link
        line-height: 1.5

    &-Text
        max-width: 600px
        min-width: 100px
        text-align: center

    &-Image
      height: 33px

    &-ScrollUp
      width: 60px
      height: 60px
      border: none
      background: none
      cursor: pointer
      svg
        width: 100%
        height: 100%
